import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  unititializeApp,
  updateUser,
  updateNotificationSeenTime,
} from 'actions';
import Auth from '@aws-amplify/auth';
import { Col, Row, Dropdown } from 'react-bootstrap';
import styles from './styles.module.css';
import EditNotififcationSubscriptionsOverlay from 'components/overlays/EditNotififcationSubscriptionsOverlay';
import NotificationsOverlay from 'components/overlays/NotificationsOverlay';
import notificationsIcon from 'assets/icons/notificationsIcon.png';
import settingsIcon from 'assets/icons/settingsIcon.png';

const UserControl = (props) => {
  const {
    rUser,
    unititializeApp,
    updateUser,
    updateNotificationSeenTime,
    rNotificationSeenTime,
  } = props;

  const [subscriptionOverlayOpen, setSubscriptionOverlayOpen] = useState(false);
  const [notificationOverlayOpen, setNotificationOverlayOpen] = useState(false);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  useEffect(() => {
    const count = rUser.notifications.filter(
      (notification) =>
        notification.extra.timestamp * 1000 > rNotificationSeenTime,
    ).length;
    setUnreadNotificationCount(count);
  }, [rNotificationSeenTime, rUser]);

  useEffect(() => {
    if (notificationOverlayOpen) {
      updateNotificationSeenTime();
    }
  }, [rUser, notificationOverlayOpen, updateNotificationSeenTime]);

  const handleLogout = async () => {
    unititializeApp();
    await Auth.signOut();
    window.location.replace(
      `${window.location.protocol}//${window.location.host}`,
    );
  };

  const handleSaveUser = async (filters) => {
    const newSubscriptions = [];
    const updatedUser = rUser;
    Object.keys(filters.daily).forEach((index) => {
      newSubscriptions.push({
        nt: index,
        dy: filters.daily[index],
        rt: filters.realtime[index],
      });
    });
    updatedUser.subscriptions = newSubscriptions;
    await updateUser(updatedUser);
  };

  return (
    <>
      <Row className={styles.container}>
        <Col>
          <img
            src={notificationsIcon}
            alt=""
            className={styles.icon}
            onClick={() => {
              setNotificationOverlayOpen(true);
              updateNotificationSeenTime();
            }}
          />
          {unreadNotificationCount > 0 ? (
            <span className={styles.badge}>
              {unreadNotificationCount > 99 ? '99+' : unreadNotificationCount}
            </span>
          ) : null}
        </Col>
        <Col>
          <Dropdown>
            <Dropdown.Toggle
              className={styles.userDisplay}
              style={{ background: 'transparent', color: 'var(--CL_BLACK)' }}
            >
              <img src={settingsIcon} alt="" className={styles.icon} />
            </Dropdown.Toggle>
            <Dropdown.Menu className={styles.menu}>
              <Dropdown.Header
                style={{
                  background: 'var(--CL_GRAY_51)',
                  color: 'var(--CL_WHITE)',
                }}
                className={styles.menuHeader}
              >
                {rUser.first_name} {rUser.last_name}
              </Dropdown.Header>
              <Dropdown.Item
                style={{
                  background: 'var(--CL_GRAY_89)',
                  color: 'var(--CL_WHITE)',
                }}
                className={styles.menuItem}
                onSelect={() => setSubscriptionOverlayOpen(true)}
              >
                User profile
              </Dropdown.Item>
              <Dropdown.Item
                style={{
                  background: 'var(--CL_GRAY_89)',
                  color: 'var(--CL_WHITE)',
                }}
                className={styles.menuItem}
              >
                Contact admin
              </Dropdown.Item>
              <Dropdown.Divider className={styles.menuDivider} />
              <Dropdown.Item
                style={{
                  background: 'var(--CL_GRAY_89)',
                  color: 'var(--CL_WHITE)',
                }}
                className={styles.menuItem}
                onSelect={handleLogout}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <EditNotififcationSubscriptionsOverlay
        user={rUser}
        isOpen={subscriptionOverlayOpen}
        onCancel={() => setSubscriptionOverlayOpen(false)}
        onDone={(currentFilter) => {
          setSubscriptionOverlayOpen(false);
          handleSaveUser(currentFilter);
        }}
      />
      <NotificationsOverlay
        user={rUser}
        isOpen={notificationOverlayOpen}
        onDone={() => setNotificationOverlayOpen(false)}
      />
    </>
  );
};

const state = (state) => {
  return {
    rUser: state.user,
    rNotificationSeenTime: state.notificationSeenTime,
  };
};

const actions = {
  unititializeApp,
  updateUser,
  updateNotificationSeenTime,
};

export default connect(state, actions)(UserControl);
