import { UserFactory } from 'classes';
import store from 'store';
export const GET_USER = 'GET_USER';
export const UPDATE_USER_NOTIFICATIONS = 'UPDATE_USER_NOTIFICATIONS';
export const UPDATE_USER = 'UPDATE_USER';

export function getUser(user) {
  return async (dispatch) => {
    if (!user) {
      user = await UserFactory.getUser();
    }
    dispatch({
      type: GET_USER,
      user,
    });
  };
}

export function getUserNotifications(dt = null) {
  return async (dispatch) => {
    let lastNtDt;
    const { user } = store.getState();
    const { notifications: currentNotifications } = user;

    if (!dt) {
      lastNtDt = currentNotifications.reduce((dt, notification) => {
        return notification && notification.created > dt
          ? notification.created
          : dt;
      }, null);
    }

    const notifications = await UserFactory.getUserNotifications(
      dt || lastNtDt,
    );

    let notificationList =
      currentNotifications &&
      Array.isArray(currentNotifications) &&
      currentNotifications.length
        ? [...currentNotifications]
        : [];

    if (notifications.length) {
      notificationList = notificationList.concat(notifications);
    }

    const newUser = await UserFactory.getUser(
      Object.assign({}, user, {
        notifications: notificationList,
      }),
    );

    dispatch({
      type: UPDATE_USER_NOTIFICATIONS,
      user: newUser,
    });
  };
}

export function updateUser(user) {
  return async (dispatch) => {
    await user.save().then((savedUser) => {
      dispatch({
        type: UPDATE_USER,
        user: savedUser,
      });
    });
  };
}
