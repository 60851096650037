/* eslint-disable no-use-before-define */

import { DFPSingleton } from '@dfp/common';

import { userGET, userPOST, userPUT, userNotificationsGET } from 'libs/api';
import { User } from 'models';

export class UserFactory extends DFPSingleton {
  static getUser(userData) {
    if (userData) {
      return new User(userData);
    }
    return userGET()
      .then((response) => {
        const {
          data: { error, result },
          status,
        } = response;
        if (error) {
          console.warn(`Error retrieving user: ${error}`);
          return { status };
        }
        if (result !== 'OK') {
          return UserFactory.getUser(result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static getUserNotifications(dt = null) {
    return userNotificationsGET(dt)
      .then((response) => {
        const {
          data: { error, result },
        } = response;
        if (error) {
          console.warn(`Error retrieving user notifications: ${error}`);
        }
        if (result !== 'OK') {
          return result;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
}

async function save() {
  let promise;

  if (this.created === null) {
    promise = userPOST(this.export());
  } else {
    promise = userPUT(this.export());
  }

  return promise.then(async (response) => {
    const {
      data: { error, result },
    } = response;
    if (error) {
      console.warn(`Error Updating user: ${error}`);
    }
    if (result !== 'OK') {
      return await UserFactory.getUser(result);
    }
  });
}
User.prototype.save = save;
