import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import LoadingSpinner from 'components/LoadingSpinner';
import { UserFactory } from 'classes';

import jwt_decode from 'jwt-decode';

import { initializeApp, unititializeApp } from 'actions';
import {
  DEV_USER,
  PATHS,
  ROLES,
  ADMIN_PATHS,
  ROLE_MAINTENANCE,
} from 'constants';
import { withAuth, Log } from 'libs/oidc';
import store, { history } from 'store';

import Dashboard from 'view/pages/default/Dashboard';
import Flight from 'view/pages/default/Flight';
import LRUFaults from 'view/pages/default/LRUFaults';
import Ship from 'view/pages/default/Ship';
import Reports from 'view/pages/default/Reports';
import ConfigurationHistory from 'view/pages/default/ConfigurationHistory';
import Unauthorized from 'view/pages/default/Unauthorized';
import Maintenance from 'view/pages/default/Maintenance';

const { configuration } = store.getState();
let startedInitialization = false;

const initApp = (user) => {
  // init app
  if (!startedInitialization) {
    startedInitialization = true;
    store.dispatch(initializeApp(user));
  }
};

const compareRoles = (dbUserRoles, pingUserRoles) => {
  if (dbUserRoles.length !== pingUserRoles.length) {
    return false;
  }
  const sortedArr1 = dbUserRoles.sort((a, b) => a.id - b.id);
  const sortedArr2 = pingUserRoles.sort((a, b) => a.id - b.id);
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i].id !== sortedArr2[i].id) {
      return false;
    }
  }
  return true;
};

const getUser = (pingUser) => {
  return UserFactory.getUser()
    .then(async (dbUser) => {
      pingUser = await UserFactory.getUser(pingUser);
      if (!compareRoles(dbUser.roles, pingUser.roles)) {
        dbUser.roles = pingUser.roles;
        const newUser = await UserFactory.getUser(dbUser);
        return await newUser.save();
      }
      return dbUser;
    })
    .catch(async (error) => {
      const {
        response: { status },
      } = error;
      if (status === 404) {
        pingUser.id = pingUser.ppr;
        pingUser.first_name = pingUser.given_name;
        pingUser.last_name = pingUser.family_name;
        let newUser = await UserFactory.getUser(pingUser);
        newUser = await newUser.save();
        initApp(newUser);
      }
    });
};

class App extends Component {
  async componentDidMount() {
    const { rConfiguration } = this.props;
    const globalAuthEnabled = rConfiguration.ff_global_auth;

    if (!globalAuthEnabled) {
      const dbUser = await getUser(DEV_USER);
      initApp(dbUser);
    }
  }

  async componentDidUpdate() {
    Log.logger = console;
    Log.level = Log.DEBUG;
    const { rConfiguration } = this.props;

    const globalAuthEnabled = rConfiguration.ff_global_auth;

    // Fetching User from the backend;
    if (globalAuthEnabled) {
      const { isLoading, userData: user } = this.props;
      if (isLoading || !user || user.expired) {
        return <LoadingSpinner display="ellipsis" />;
      }

      const userData = Object.assign({}, user.profile);
      userData.roles = [];
      const accessData = jwt_decode(user.access_token);
      if (accessData.roles) {
        userData.roles = ['Admin', 'Maintenance', 'Marketing', 'User'].reduce(
          (acc, internalRole) => {
            if (
              (Array.isArray(accessData.roles) &&
                accessData.roles.some((accessRole) =>
                  accessRole.includes(internalRole),
                )) ||
              (typeof accessData.roles === 'string' &&
                accessData.roles.includes(internalRole))
            ) {
              if (internalRole === 'Admin') {
                // @todo rename this internal role and update all referencing code
                acc.push(ROLES.getEnumerationItem('Administrator').id);
              } else {
                acc.push(ROLES.getEnumerationItem(internalRole).id);
              }
            }
            return acc;
          },
          [],
        );
      }
      // Check for unthorized users
      if (userData.roles.length < 1) {
        return <Unauthorized />;
      }

      const dbUser = await getUser(
        Object.assign({}, userData, {
          email: accessData.email || null,
        }),
      );

      initApp(dbUser);
    }
  }

  render() {
    const { rConfiguration, rInitialized, rUser } = this.props;

    const lrusAndFaultsEnabled = rConfiguration.ff_lrus_and_faults;
    const rssiHeatmapEnabled = rConfiguration.ff_rssi_heatmap;
    const maintenanceEnabled = rUser?.roles.includes(ROLE_MAINTENANCE);

    // push user to admin app for admin routes
    const route = sessionStorage.getItem('route');
    if (Object.values(ADMIN_PATHS).indexOf(route) !== -1) {
      window.location = ADMIN_PATHS.home;
      return <></>;
    }

    if (!rInitialized) {
      return <LoadingSpinner />;
    }

    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Redirect
            exact
            from={PATHS.home}
            to={{
              pathname: PATHS.dashboard,
              search: window.location.search, // preserve params
            }}
          />
          <Route exact path={PATHS.dashboard} component={Dashboard} />
          <Route exact path={PATHS.flights} component={Flight} />
          <Route exact path={PATHS.flightsUsage} component={Flight} />
          <Route
            exact
            path={`${PATHS.ship}/:page?/:lru_pos?`}
            component={Ship}
          />
          <Route exact path={PATHS.flightsOverview} component={Flight} />
          {rssiHeatmapEnabled && (
            <Route exact path={PATHS.flightsHeatmap} component={Flight} />
          )}
          <Route
            exact
            path={`${PATHS.configurationHistory}/:id`}
            component={ConfigurationHistory}
          />
          {lrusAndFaultsEnabled && (
            <Route exact path={`${PATHS.lrus}/:page?`} component={LRUFaults} />
          )}
          {maintenanceEnabled && (
            <Route exact path={PATHS.maintenance} component={Maintenance} />
          )}
          <Route exact path={PATHS.reports} component={Reports} />
          <Route exact path={`${PATHS.reportShip}/:id?`} component={Reports} />
          <Route
            exact
            path={`${PATHS.reportFlight}/:id?`}
            component={Reports}
          />
          <Route
            exact
            path={`${PATHS.reportLruFaults}/:id?`}
            component={Reports}
          />
          <Route
            exact
            path={`${PATHS.reportLruDetail}/:id?`}
            component={Reports}
          />
          <Route exact path={`${PATHS.reportUsage}/:id?`} component={Reports} />
          <Route exact path={PATHS.reportAvailability} component={Reports} />
          <Route
            exact
            path={PATHS.reportConfigurableFeatures}
            component={Reports}
          />
          <Redirect to={PATHS.home} />
        </Switch>
      </ConnectedRouter>
    );
  }

  componentWillUnmount() {
    store.dispatch(unititializeApp());
  }
}

const state = (state) => {
  return {
    rConfiguration: state.configuration,
    rInitialized: state.initialized,
    rUser: state.user,
  };
};

const actions = {};

export default connect(
  state,
  actions,
)(configuration.ff_global_auth ? withAuth(App) : App);
