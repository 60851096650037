import React from 'react';
import config from 'config';
import { ADMIN_PATHS, KIOSK_PATHS, ROLE_ADMINISTRATOR } from 'constants';
import { Button, Container, Row, Col } from 'react-bootstrap';
import IfCan from 'containers/IfCan';
import styles from './styles.module.css';
const DebugPanel = (props) => {
  const { configuration, user } = props;
  return (
    <div className={styles.root}>
      <div>
        <h1>Debug panel</h1>
      </div>
      <div className={styles.debugContainer}>
        <h2>Versions</h2>
        <Container>
          {Object.keys(config.versions).map((component, i) => {
            return (
              <Row key={i}>
                <Col>{component}</Col>
                <Col md="auto">{config.versions[component]}</Col>
              </Row>
            );
          })}
        </Container>
      </div>
      <div className={styles.debugContainer}>
        <h2>Configuration</h2>
        <Container>
          {Object.keys(configuration).map((item, i) => {
            return (
              <Row key={i}>
                <Col>{item}</Col>
                {item === 'log_level' ? (
                  <Col md="auto">{configuration[item]}</Col>
                ) : (
                  <Col md="auto">{configuration[item] ? 'On' : 'Off'}</Col>
                )}
              </Row>
            );
          })}
        </Container>
      </div>
      <div className={styles.debugContainer}>
        <h2>User</h2>
        <Container>
          <Row>
            <Col>Name</Col>
            <Col md="auto">
              {user?.given_name} {user?.family_name}
            </Col>
          </Row>
          <Row>
            <Col>Roles</Col>
          </Row>
          {user.roles.map((role, i) => {
            return (
              <Row key={i}>
                <Col></Col>
                <Col md="auto">{role.value.name}</Col>
              </Row>
            );
          })}
        </Container>
      </div>
      <div>
        <h2>Tools</h2>
        <Container>
          <IfCan allowedRoles={[ROLE_ADMINISTRATOR.value.name]}>
            <Row className={styles.buttonContainer}>
              <Col>Admin console</Col>
              <Col md="auto">
                <Button
                  data-cy-id="Global-Debug-AdminButton"
                  variant="light"
                  className={styles.debugButton}
                  onClick={() => (window.location = ADMIN_PATHS.home)}
                >
                  Go
                </Button>
              </Col>
            </Row>
          </IfCan>
          <IfCan
            override={configuration.ff_kiosk_mode}
            allowedRoles={[ROLE_ADMINISTRATOR.value.name]}
          >
            <Row className={styles.buttonContainer}>
              <Col>Kiosk mode</Col>
              <Col md="auto">
                <Button
                  data-cy-id="Global-Debug-KioskButton"
                  variant="light"
                  className={styles.debugButton}
                  onClick={() => (window.location = KIOSK_PATHS.home)}
                >
                  Go
                </Button>
              </Col>
            </Row>
          </IfCan>
        </Container>
      </div>
    </div>
  );
};

export default DebugPanel;
