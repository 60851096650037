import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { setActiveFlight } from 'actions';
import {
  AIRCRAFT_STATUS_OFFLINE,
  AIRPORT_UNKONWN,
  FLIGHT_STATUS_UNKONWN,
  NOT_APPLICABLE,
  NOT_AVAILABLE,
  PATHS,
  ROLE_MARKETING,
} from 'constants';
import { Aircraft, Flight } from 'models';
import {
  convertMinutesToTime,
  getAircraftHealthHistogramData,
  getFlightHealthHistogramData,
  getUTCTime,
} from 'utils';

import { Button, Col, Row } from 'react-bootstrap';
import FlightTracker from 'components/FlightTracker';
import HealthGauge from 'components/HealthGauge';
import HealthHistogram from 'components/HealthHistogram';
import IfCan from 'containers/IfCan';

import styles from './styles.module.css';

const AircraftDetailsPanel = (props) => {
  const { aircraft, currentTime, flight, push, setActiveFlight, view } = props;

  // aircraft details
  const { aircraft_type: aircrafType, status } = aircraft;
  const tail = aircraft.tail || NOT_AVAILABLE;
  const aircraftTypeName = aircrafType ? aircrafType.value.name : NOT_AVAILABLE;
  const aircraftStatusName = status
    ? status.value.name
    : AIRCRAFT_STATUS_OFFLINE.value.name;
  const isOffline = status ? status.id === AIRCRAFT_STATUS_OFFLINE.id : true;
  const totalItuCount = aircraft.lopa.getDeviceCount('passenger_displays');
  const latestHistory = aircraft.getLatestHistory() || {};
  const { created: aircraftLastEventTimestamp, health_score: healthScore } =
    latestHistory;
  const lastUpdateTime = aircraftLastEventTimestamp
    ? `${convertMinutesToTime((currentTime - aircraftLastEventTimestamp) / 1000 / 60)} ago`
    : NOT_APPLICABLE;

  // flight details
  const depIATA =
    flight?.departure_airport?.value.iata || AIRPORT_UNKONWN.value.iata;
  const desIATA =
    flight?.destination_airport?.value.iata || AIRPORT_UNKONWN.value.iata;
  const events = flight?.events || [];
  const flightArrTimestamp = flight?.arrival_timestamp || 0;
  const flightDepTimestamp = flight?.departure_timestamp || 0;
  const flightHistory = flight?.history || [];
  const flightNumber = flight?.number || NOT_AVAILABLE;
  const flightStatusName = flight?.status?.value.name || FLIGHT_STATUS_UNKONWN;

  // latest history
  const flightLatestHistory = flight?.getLatestHistory() || {};
  const flightLatestHistoryTimestamp = flightLatestHistory.created || 0;
  const flightLatestHistoryActiveUserCount =
    flightLatestHistory.pax_active_count || 0;
  const flightLatestHistoryOnlineItuCount =
    flightLatestHistory.pax_online_count || 0;
  const flightLatestHistoryTotalItuResets =
    flightLatestHistory.pax_reset_count || 0;

  const onlineItuPercentage =
    flightLatestHistoryOnlineItuCount > 0
      ? Math.floor((flightLatestHistoryOnlineItuCount / totalItuCount) * 100)
      : 0;
  const activeUserPercentage =
    flightLatestHistoryActiveUserCount > 0
      ? Math.floor((flightLatestHistoryActiveUserCount / totalItuCount) * 100)
      : 0;

  const tsd = flightLatestHistory.time_since_departure || 0;
  const ttd = flightLatestHistory.time_to_destination || 0;
  const tft = tsd + ttd;

  const depTimestamp =
    flightDepTimestamp || flightLatestHistoryTimestamp - tsd * 60 * 1000;
  const arrTimestamp =
    flightArrTimestamp || flightLatestHistoryTimestamp + ttd * 60 * 1000;

  let missedTransfers = '--';
  if (!flight?.is_open) {
    let totalIntervals = Math.floor(
      (flightLatestHistoryTimestamp - flight.departure_timestamp) /
        1000 /
        60 /
        15,
    );
    if (totalIntervals < 0) totalIntervals = 0;
    const transferEventCount = events.filter(
      ({ event_type }) => event_type.id === '14',
    ).length;
    let missedTransferCount = totalIntervals - transferEventCount || 0;
    if (missedTransferCount < 0) missedTransferCount = 0;
    const missedTransferPercentage =
      totalIntervals > 0
        ? Math.round((missedTransferCount / totalIntervals) * 100)
        : 0;
    missedTransfers = `${missedTransferCount} / ${totalIntervals} (${missedTransferPercentage}%)`;
  }

  const goToFlight = () => {
    setActiveFlight(flight);
    push(PATHS.flights);
  };

  let showBottomGraphs = true;
  let showHeader = true;
  let showTopGraphs = false;
  let showFlightStatistics = aircraft.status.id !== AIRCRAFT_STATUS_OFFLINE.id;
  let histogramData;
  switch (view) {
    case 'flightDetail':
      histogramData = getFlightHealthHistogramData(flightHistory);
      showBottomGraphs = false;
      showHeader = false;
      showTopGraphs = !flight.is_open;
      showFlightStatistics = true;
      break;

    default:
      histogramData = getAircraftHealthHistogramData(aircraft.history);
      break;
  }

  return (
    <Row className={styles.root} data-cy-id="AircraftDetailsPanel" noGutters>
      <Col>
        {!showHeader ? null : (
          <Row
            data-cy-id="AircraftDetailsPanel-HeaderSection"
            className={styles.rightPanelInfo}
          >
            <Col>
              <Row>
                <Col className={styles.header}>
                  <Button
                    variant="link"
                    className={styles.openAircraftButton}
                    data-cy-id="AircraftDetailsPanel-TailButton"
                    onClick={() => push(PATHS.ship)}
                  >
                    {tail}
                  </Button>
                  <p>{aircraftTypeName}</p>
                </Col>
                {isOffline ? null : (
                  <Col className={styles.openAircraftContainer}>
                    <Button
                      className={classnames(
                        'button_foc',
                        styles.openFlightButton,
                      )}
                      data-cy-id="AircraftDetailsPanel-OpenFlightButton"
                      onClick={goToFlight}
                    >
                      Open flight
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        )}
        <Row
          data-cy-id="AircraftDetailsPanel-FlightTrackerSection"
          className={styles.rightPanelInfo}
        >
          <Col>
            <FlightTracker aircraft={aircraft} flight={flight} />
          </Col>
        </Row>
        {showTopGraphs ? (
          <Row
            data-cy-id="AircraftDetailsPanel-HealthStateSection"
            className={styles.rightPanelInfo}
          >
            <Col className={styles.flightGraphContainer}>
              <HealthHistogram histogramData={histogramData} />
              <Row>
                <Col>
                  <strong
                    className={classnames(
                      styles.chartLabel,
                      styles.chartLabelDep,
                    )}
                  >
                    {depIATA}
                  </strong>
                </Col>
                <Col>
                  <strong className={styles.chartLabel}>{desIATA}</strong>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <IfCan forbiddenRoles={[ROLE_MARKETING.value.name]}>
            <Row
              data-cy-id="AircraftDetailsPanel-HealthStateSection"
              className={styles.rightPanelInfo}
            >
              <Col>
                <Row>
                  <Col
                    data-cy-id="AircraftDetailsPanel-HealthStateLabel"
                    xs={5}
                  >
                    <strong>Ship</strong>
                    <br />
                    <em>{lastUpdateTime}</em>
                  </Col>
                  <Col xs={7} className="p-0">
                    <span
                      data-cy-id="AircraftDetailsPanel-HealthStateBar"
                      className={classnames(
                        'btn',
                        'btn-block',
                        styles.aircraftStatus,
                        styles[aircraftStatusName],
                      )}
                    >
                      {aircraftStatusName}
                      {aircraft.status.id ===
                      AIRCRAFT_STATUS_OFFLINE.id ? null : (
                        <span className={styles.aircraftStatusPercentage}>
                          ({healthScore}%)
                        </span>
                      )}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
          </IfCan>
        )}
        {!showFlightStatistics ? null : (
          <>
            <Row
              data-cy-id="AircraftDetailsPanel-FlightStatisticsSection"
              className={styles.rightPanelInfo}
              style={!showBottomGraphs ? { borderBottom: 'none' } : null}
            >
              <Col className={styles.noSpace}>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong>Flight:</strong>
                  </Col>
                  <Col>{flightNumber}</Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong>Location:</strong>
                  </Col>
                  <Col>
                    {depIATA}-{desIATA}
                  </Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong>Flight status:</strong>
                  </Col>
                  <Col>{flightStatusName}</Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong>Departure:</strong>
                  </Col>
                  <Col className={styles.nowrap}>
                    {depTimestamp
                      ? `${getUTCTime(depTimestamp)} UTC`
                      : NOT_AVAILABLE}
                  </Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong>Arrival:</strong>
                  </Col>
                  <Col className={styles.nowrap}>
                    {arrTimestamp
                      ? `${getUTCTime(arrTimestamp)} UTC`
                      : NOT_AVAILABLE}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong>Flight time</strong>
                  </Col>
                  <Col></Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong className={styles.indent}>Total:</strong>
                  </Col>
                  <Col>{tft ? convertMinutesToTime(tft) : NOT_AVAILABLE}</Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong className={styles.indent}>Elapsed:</strong>
                  </Col>
                  <Col>{tsd ? convertMinutesToTime(tsd) : NOT_AVAILABLE}</Col>
                </Row>
                <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                  <Col>
                    <strong className={styles.indent}>Remaining:</strong>
                  </Col>
                  <Col>{ttd ? convertMinutesToTime(ttd) : NOT_AVAILABLE}</Col>
                </Row>
                <IfCan forbiddenRoles={[ROLE_MARKETING.value.name]}>
                  <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                    <Col>
                      <strong>Missed&nbsp;transfers:</strong>
                    </Col>
                    <Col>{missedTransfers}</Col>
                  </Row>
                  <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                    <Col>
                      <strong>PAX total:</strong>
                    </Col>
                    <Col>{totalItuCount}</Col>
                  </Row>
                  <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                    <Col>
                      <strong>PAX online:</strong>
                    </Col>
                    <Col>
                      {flight.is_open
                        ? flightLatestHistoryOnlineItuCount
                          ? `${flightLatestHistoryOnlineItuCount} (${onlineItuPercentage}%)`
                          : NOT_AVAILABLE
                        : '--'}
                    </Col>
                  </Row>
                  <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                    <Col>
                      <strong>Active users:</strong>
                    </Col>
                    <Col>
                      {flight.is_open
                        ? flightLatestHistoryActiveUserCount
                          ? `${flightLatestHistoryActiveUserCount} (${activeUserPercentage}%)`
                          : NOT_AVAILABLE
                        : '--'}
                    </Col>
                  </Row>
                  <Row data-cy-class="AircraftDetailsPanel-DetailsRow">
                    <Col>
                      <strong>PAX resets:</strong>
                    </Col>
                    <Col>
                      {flightLatestHistoryTotalItuResets
                        ? flightLatestHistoryTotalItuResets
                        : NOT_AVAILABLE}
                    </Col>
                  </Row>
                </IfCan>
              </Col>
            </Row>
            {!showBottomGraphs ? null : (
              <Row
                data-cy-id="AircraftDetailsPanel-HealthChartsSection"
                className={styles.charts}
              >
                <Col>
                  <Row>
                    <Col>
                      <p>Health</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      data-cy-id="AircraftDetailsPanel-CurrentHealthChart"
                      xs={4}
                      className={styles.col}
                    >
                      <HealthGauge healthScore={healthScore} />
                      <strong className={styles.chartLabel}>Current</strong>
                    </Col>
                    <Col
                      data-cy-id="AircraftDetailsPanel-WeeklyHealthChart"
                      xs={{ size: 7, offset: 1 }}
                      className={styles.col}
                    >
                      <HealthHistogram histogramData={histogramData} />
                      <strong className={styles.chartLabel}>7 day</strong>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};

AircraftDetailsPanel.defaultProps = {
  flight: new Flight(),
};

AircraftDetailsPanel.propTypes = {
  aircraft: PropTypes.instanceOf(Aircraft).isRequired,
  flight: PropTypes.instanceOf(Flight),
  view: PropTypes.string,
};

const state = (state) => {
  return {
    currentTime: state.currentTime,
  };
};

const actions = {
  push,
  setActiveFlight,
};

export default connect(state, actions)(AircraftDetailsPanel);
